import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sortByPipe',
  pure: false
})
export class SortByPipe implements PipeTransform {
    transform(list: any , name: string, direction: string): any {
        const sortedColumns: { [key: string]: string } = {date: 'descend'};
        Object.keys(sortedColumns)
                .forEach(column =>  sortedColumns[column] =
                        column === name ? (!direction ? 'ascend' : direction) : null);

        const sortData = Object.keys(sortedColumns)
            .filter(column => sortedColumns[column] !== null)
            .map(column => ({name: column, direction: sortedColumns[column]}))[0];

        list = list.sort((a, b) =>
        sortData.direction === 'ascend'
            ? a[sortData.name] > b[sortData.name] ? 1 : -1
            : b[sortData.name] > a[sortData.name] ? 1 : -1
        );

        return [...list];
    }
}
