export const ngZorroIT = {
    locale: 'it',
    Pagination: {
        items_per_page: 'IT_/ página',
        jump_to: 'IT_Ir a',
        jump_to_confirm: 'IT_confirmar',
        page: '',
        prev_page: 'IT_Página anterior',
        next_page: 'IT_Página siguiente',
        prev_5: 'IT_5 páginas previas',
        next_5: 'IT_5 páginas siguientes',
        prev_3: 'IT_3 páginas previas',
        next_3: 'IT_3 páginas siguientes'
    },
    DatePicker: {
        lang: {
            placeholder: 'IT_Seleccionar fecha',
            rangePlaceholder: ['IT_Fecha inicial', 'IT_Fecha final'],
            today: 'IT_Hoy',
            now: 'IT_Ahora',
            backToToday: 'IT_Volver a hoy',
            ok: 'IT_Aceptar',
            clear: 'IT_Limpiar',
            month: 'IT_Mes',
            year: 'IT_Año',
            timeSelect: 'IT_Seleccionar hora',
            dateSelect: 'IT_Seleccionar fecha',
            monthSelect: 'IT_Elegir un mes',
            yearSelect: 'IT_Elegir un año',
            decadeSelect: 'IT_Elegir una década',
            yearFormat: 'yyyy',
            dateFormat: 'dd/MM/yyyy',
            dayFormat: 'dd',
            dateTimeFormat: 'dd/MM/yyyy HH:mm',
            monthBeforeYear: true,
            previousMonth: 'IT_Mes anterior (PageUp)',
            nextMonth: 'IT_Mes siguiente (PageDown)',
            previousYear: 'IT_Año anterior (Control + left)',
            nextYear: 'IT_Año siguiente (Control + right)',
            previousDecade: 'IT_Década anterior',
            nextDecade: 'IT_Década siguiente',
            previousCentury: 'IT_Siglo anterior',
            nextCentury: 'IT_Siglo siguiente'
        },
        timePickerLocale: {
            placeholder: 'IT_Seleccionar hora'
        }
    },
    TimePicker: {
        placeholder: 'IT_Seleccionar hora'
    },
    Calendar: {
        today: 'IT_Hoy',
        now: 'IT_Ahora',
        backToToday: 'IT_Volver a hoy',
        ok: 'IT_Aceptar',
        clear: 'IT_Limpiar',
        month: 'IT_Mes',
        year: 'IT_Año',
        timeSelect: 'IT_Seleccionar hora',
        dateSelect: 'IT_Seleccionar fecha',
        monthSelect: 'IT_Elegir un mes',
        yearSelect: 'IT_Elegir un año',
        decadeSelect: 'IT_Elegir una década',
        yearFormat: 'yyyy',
        dateFormat: 'dd/MM/yyyy',
        dayFormat: 'dd',
        dateTimeFormat: 'dd/MM/yyyy HH:mm',
        monthBeforeYear: true,
        previousMonth: 'IT_Mes anterior (PageUp)',
        nextMonth: 'IT_Mes siguiente (PageDown)',
        previousYear: 'IT_Año anterior (Control + left)',
        nextYear: 'IT_Año siguiente (Control + right)',
        previousDecade: 'IT_Década anterior',
        nextDecade: 'IT_Década siguiente',
        previousCentury: 'IT_Siglo anterior',
        nextCentury: 'IT_Siglo siguiente'
    },
    global: {
        placeholder: 'IT_Seleccione'
    },
    Table: {
        filterTitle: 'IT_Filtrar menú',
        filterConfirm: 'IT_Aceptar',
        filterReset: 'IT_Reiniciar',
        selectAll: 'IT_Seleccionar todo',
        selectInvert: 'IT_Invertir selección',
        sortTitle: 'IT_Ordenar'
    },
    Modal: {
        okText: 'IT_Aceptar',
        cancelText: 'IT_Cancelar',
        justOkText: 'IT_Aceptar'
    },
    Popconfirm: {
        okText: 'IT_Aceptar',
        cancelText: 'IT_Cancelar'
    },
    Transfer: {
        searchPlaceholder: 'IT_Buscar aquí',
        itemUnit: 'IT_elemento',
        itemsUnit: 'IT_elementos'
    },
    Upload: {
        uploading: 'IT_Subiendo...',
        removeFile: 'IT_Eliminar archivo',
        uploadError: 'IT_Error al subir el archivo',
        previewFile: 'IT_Vista previa'
    },
    Empty: {
        description: 'IT_No hay datos'
    },
    Icon: {
        icon: 'IT_ícono'
    },
    Text: {
        edit: 'IT_editar',
        copy: 'IT_copiar',
        copied: 'IT_copiado',
        expand: 'IT_expandir'
    },
    PageHeader: {
        back: 'IT_volver'
    }
};
