export const dateLocaleDE = [
    'de',
    [
        ['v. m.', 'n. m.'],
        ['vorm.', 'nachm.'],
        null
    ],
    null,
    [
        ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
        ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
        ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA']
    ],
    null,
    [
        ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
        ['Jan.', 'Feb.', 'März.', 'Apr.', 'Mai.', 'Juni.', 'Juli.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dez.'],
        ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
    ],
    null,
    [
        ['v. Chr.', 'n. Chr.'],
        null,
        null
    ],
    1,
    [6, 0],
    ['dd.MM.yy', 'dd.MM.y', 'd. MMMM y', 'EEEE, d. MMMM y'],
    ['HH:mm', 'HH:mm:ss', 'HH:mm:ss (z)', 'HH:mm:ss (zzzz)'],
    ['{1}, {0}', null, '{1} \'um\' {0}', null],
    [',', '.', ';', '%', '+', '−', 'E', '×', '‰', '∞', 'NaN', ':'],
    ['#,##0.###', '#,##0 %', '#,##0.00 ¤', '#E0'],
    '€',
    'Euro', {
        AUD: [null, '$'],
        BRL: [null, 'R$'],
        CNY: [null, '¥'],
        EGP: [],
        ESP: ['₧'],
        GBP: [null, '£'],
        HKD: [null, '$'],
        ILS: [null, '₪'],
        INR: [null, '₹'],
        JPY: [null, '¥'],
        KRW: [null, '₩'],
        MXN: [null, '$'],
        NZD: [null, '$'],
        RON: [null, 'L'],
        THB: ['฿'],
        TWD: [null, 'NT$'],
        USD: ['US$', '$'],
        XAF: [],
        XCD: [null, '$'],
        XOF: []
    },
    null
];
