import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/services/app.service';
import { ApiService } from 'src/services/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-alarm-list',
  templateUrl: './alarm-list.component.html',
  styleUrls: ['./alarm-list.component.css']
})
export class AlarmListComponent implements OnInit {

  @Input() patientsWithAlarms;

  constructor(private router: Router,
              private appService: AppService,
              private apiService: ApiService,
              private translateService: TranslateService,
              private notificationService: NzNotificationService) {}

  ngOnInit() {}

  goPatientPage(patient: any): void {
    patient.searching = true;
    this.apiService.getPatient(patient.privateId)
      .then(patientData => {
        this.appService.getDrawer().close();
        this.appService.setPatient(patientData);
        this.router.navigate(['/patientPage']);
        patient.searching = false;
      });
  }
}
