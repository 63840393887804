export const dateLocaleEN = [
    'en',
    [
        ['a. m.', 'p. m.'],
        null,
        null
    ],
    null,
    [
        ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'],
        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
    ],
    null,
    [
        ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
        ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'],
        ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    ],
    null,
    [
        ['B. C.', 'A. D.'],
        null,
        ['Before Christ', 'Anno Domini']
    ],
    0,
    [6, 0],
    ['M/d/yy', 'MMM d, y', 'MMMM d, y', 'EEEE, MMMM d, y'],
    ['HH:mm a', 'HH:mm:ss a', 'HH:mm:ss a (z)', 'HH:mm:ss a (zzzz)'],
    ['{1} {0}', null, '{1} \'at\' {0}', null],
    ['.', ',', ';', '%', '+', '−', 'E', '×', '‰', '∞', 'NaN', ':'],
    ['#,##0.###', '#,##0%', '¤#,##0.00', '#E0'],
    '$',
    'US Dollar', {
        AUD: [null, '$'],
        BRL: [null, 'R$'],
        CNY: [null, '¥'],
        EGP: [],
        ESP: ['₧'],
        GBP: [null, '£'],
        HKD: [null, '$'],
        ILS: [null, '₪'],
        INR: [null, '₹'],
        JPY: [null, '¥'],
        KRW: [null, '₩'],
        MXN: [null, '$'],
        NZD: [null, '$'],
        RON: [null, 'L'],
        THB: ['฿'],
        TWD: [null, 'NT$'],
        USD: ['US$', '$'],
        XAF: [],
        XCD: [null, '$'],
        XOF: []
    },
    null
];
