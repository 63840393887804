import { AppComponent } from 'src/app/app.component';

/* SHARED HEADER*/
import { HeaderComponent } from 'src/app/header/header.component';
import { AlarmListComponent } from 'src/app/header/alarm-list/alarm-list.component';
import { UpdatePassComponent } from 'src/app/header/update-pass/update-pass.component';

/* LOGIN PAGE */
import { LoginComponent } from 'src/app/login-page/login.component';
import { SmsComponent } from 'src/app/login-page/sms/sms.component';
import { ResetPassComponent } from 'src/app/login-page/reset-pass/reset-pass.component';
import { ForgotPassComponent } from 'src/app/login-page/forgot-pass/forgot-pass.component';

/* HOME PAGE */
import { HomePageComponent } from 'src/app/home-page/home-page.component';

/* PATIENT PAGE */
import { PatientPageComponent } from 'src/app/patient-page/patient-page.component';
import { TreatmentChartComponent } from 'src/app/patient-page/treatment-chart/treatment-chart.component';
import { PrescriptionListComponent } from 'src/app/patient-page/prescription-list/prescription-list.component';
import { ActivityBandComponent } from 'src/app/patient-page/activity-band/activity-band.component';
import { EmotionalStateComponent } from 'src/app/patient-page/emotional-state/emotional-state.component';

/* TREATMENT PAGE */
import { TreatmentPageComponent } from 'src/app/treatment-page/treatment-page.component';
import { PrescriptionPageComponent } from 'src/app/treatment-page/prescription-page/prescription-page.component';

/* PIPES */
import {SortByPipe} from 'src/pipes/sort-by.pipe';

/* Lista de componentes de la aplicación
-------------------------------------------*/
export const AppDeclarations = [
    AppComponent,

    /* SHARED HEADER */
    HeaderComponent,
    AlarmListComponent,
    UpdatePassComponent,

    /* LOGIN PAGE */
    LoginComponent,
    SmsComponent,
    ResetPassComponent,
    ForgotPassComponent,

    /* HOME PAGE */
    HomePageComponent,

    /* PATIENT PAGE */
    PatientPageComponent,
    TreatmentChartComponent,
    PrescriptionListComponent,
    ActivityBandComponent,
    EmotionalStateComponent,

    /* TREATMENT PAGE */
    TreatmentPageComponent,
    PrescriptionPageComponent,

    /* PIPES */
    SortByPipe
];
