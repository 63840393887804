import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private nzI18nService: NzI18nService,
    private translateService: TranslateService,
    private appService: AppService) {}

  ngOnInit() {
    const lang = this.appService.getLang();
    this.translateService.use(lang);
    this.nzI18nService.setLocale(this.appService.getNgZorroLocale(lang));
  }

  salir(): void {
    this.router.navigate(['/login']);
  }
}
