import { AlarmListComponent } from 'src/app/header/alarm-list/alarm-list.component';
import { ResetPassComponent } from 'src/app/login-page/reset-pass/reset-pass.component';
import { UpdatePassComponent } from 'src/app/header/update-pass/update-pass.component';
import { ForgotPassComponent } from 'src/app/login-page/forgot-pass/forgot-pass.component';

/* Lista de componentes usados por servicios
-------------------------------------------
    Componente (Servicio)
*/
export const AppEntryComponents = [
    AlarmListComponent,
    ResetPassComponent,
    UpdatePassComponent,
    ForgotPassComponent
];
