export const dateLocaleIT = [
    'it',
    [
        ['m.', 'p.'],
        ['AM', 'PM'],
        null
    ],
    null,
    [
        ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
        ['Dom.', 'Lun.', 'Mar.', 'Mer.', 'Gio.', 'Ven.', 'Sab.'],
        ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        ['DO', 'LU', 'MA', 'ME', 'GI', 'VE', 'SA']
    ],
    null,
    [
        ['G', 'F', 'M', 'A', 'M', 'G', 'L', 'A', 'S', 'O', 'N', 'D'],
        ['Gen.', 'Feb.', 'Mar.', 'Apr.', 'Mag.', 'Giu.', 'Lug.', 'Ago.', 'Set.', 'Ott.', 'Nov.', 'Dic.'],
        ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
    ],
    null,
    [
        ['a. C.', 'd. C.'],
        null,
        ['avanti Cristo', 'dopo Cristo']
    ],
    1,
    [6, 0],
    ['dd/MM/yy', 'd MMM y', 'd MMMM y', 'EEEE d MMMM y'],
    ['HH:mm', 'HH:mm:ss', 'HH:mm:ss (z)', 'HH:mm:ss (zzzz)'],
    ['{1}, {0}', null, '{1} {0}', null],
    [',', '.', ';', '%', '+', '−', 'E', '×', '‰', '∞', 'NaN', ':'],
    ['#,##0.###', '#,##0%', '#,##0.00 ¤', '#E0'],
    '€',
    'Euro', {
        AUD: [null, '$'],
        BRL: [null, 'R$'],
        CNY: [null, '¥'],
        EGP: [],
        ESP: ['₧'],
        GBP: [null, '£'],
        HKD: [null, '$'],
        ILS: [null, '₪'],
        INR: [null, '₹'],
        JPY: [null, '¥'],
        KRW: [null, '₩'],
        MXN: [null, '$'],
        NZD: [null, '$'],
        RON: [null, 'L'],
        THB: ['฿'],
        TWD: [null, 'NT$'],
        USD: ['US$', '$'],
        XAF: [],
        XCD: [null, '$'],
        XOF: []
    },
    null
];
