import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/services/api.service';
import { AppService } from 'src/services/app.service';
import { SmsComponent } from 'src/app/login-page/sms/sms.component';
import { ResetPassComponent } from 'src/app/login-page/reset-pass/reset-pass.component';
import { ForgotPassComponent } from 'src/app/login-page/forgot-pass/forgot-pass.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  credentialsForm: FormGroup;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private apiService: ApiService,
    private translateService: TranslateService,
    private nzModalService: NzModalService,
    private router: Router,
    private notificationService: NzNotificationService) {
      this.credentialsForm = this.formBuilder.group({
        userName: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getEmailPattern())]),
        password: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getPasswordPattern())])
      });
  }

  ngOnInit() {}

  logIn(): void {
    this.loading = true;
    this.apiService.login(this.credentialsForm.value.userName, this.credentialsForm.value.password)
      .then((response) => {
        if (response.code === 0) {
          this.validateSMSCode(response.token);
        } else {
          if (response.code === 1) { // Usuario o contraseña incorrectos con número de intentos
            this.notificationService.create('error',
              this.translateService.instant('error.titleError'),
              this.translateService.instant('error.bodyCredentialsError', {attemps: response.remainingAttempts}));
          } else if (response.code === 2) { // Usuario bloqueado
            this.notificationService.create('error',
              this.translateService.instant('error.titleError'),
              this.translateService.instant('error.bodyBlockedUserError'));
          } else if (response.code === 3) { // Contraseña expirada
            this.resetPass();
          } else if (response.code === 4) { // Usuario no encontrado
            this.notificationService.create('error',
              this.translateService.instant('error.titleError'),
              this.translateService.instant('error.bodyUserNotFoundError'));
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  validateSMSCode(doctorToken: string): void {
    const modal = this.nzModalService.create({
      nzTitle: this.translateService.instant('loginPage.smsTitle'),
      nzContent: SmsComponent,
      nzMaskClosable: false,
      nzClosable:  false,
      nzComponentParams: {},
      nzFooter: [
        {
          label: this.translateService.instant('srt.cancel'),
          shape: 'round',
          onClick: () => modal.destroy()
        },
        {
          label: this.translateService.instant('srt.continue'),
          type: 'primary',
          shape: 'round',
          disabled: () => !modal.getContentComponent().smsCodeForm.valid,
          onClick: () => {
            return new Promise (resolve => {
              this.apiService.smsCode(doctorToken, modal.getContentComponent().smsCodeForm.value.smsCode)
              .then((response) => {
                if (response.code === 0) {
                  this.appService.setUser({
                    token: doctorToken,
                    name: response.doctor.name,
                    surname1: response.doctor.surname1,
                    surname2: response.doctor.surname2,
                    email: this.credentialsForm.value.userName
                  });
                  modal.destroy();
                  this.router.navigate(['/homePage']);
                } else {
                  this.notificationService.create('error',
                    this.translateService.instant('error.smsCodeError'),
                    this.translateService.instant('error.smsInvalidCode'));
                }
              }).finally(() => resolve());
            });
          }
        }
      ]
    });
  }

  resetPass(): void {
    const modal = this.nzModalService.create({
      nzTitle: this.translateService.instant('loginPage.newPassTitle'),
      nzContent: ResetPassComponent,
      nzMaskClosable: false,
      nzClosable:  false,
      nzFooter: [
        {
          label: this.translateService.instant('srt.cancel'),
          shape: 'round',
          onClick: () => modal.destroy()
        },
        {
          label: this.translateService.instant('srt.save'),
          type: 'primary',
          shape: 'round',
          disabled: () => !modal.getContentComponent().resetPassForm.valid,
          onClick: () => {
            return new Promise (resolve => {
              this.apiService.updatePass(this.credentialsForm.value.userName, modal.getContentComponent().resetPassForm.value.oldPass, modal.getContentComponent().resetPassForm.value.newPass, modal.getContentComponent().resetPassForm.value.newPass2)
              .then((response) => {
                if (response.code === 0) {
                  this.notificationService.create('success',
                    this.translateService.instant('loginPage.newPassTitle'),
                    this.translateService.instant('success.passUpdated'));
                  modal.destroy();
                  this.credentialsForm.reset();
                } else if (response.code === 1) {
                  this.notificationService.create('error',
                    this.translateService.instant('error.titleError'),
                    this.translateService.instant('error.passDoNotMatch'));
                } else if (response.code === 2) {
                  this.notificationService.create('error',
                    this.translateService.instant('error.titleError'),
                    this.translateService.instant('error.passInUse'));
                }
              }).finally(() => resolve());
            });
          }
        }
      ]
    });
  }

  forgotPass(): void {
    const modal = this.nzModalService.create({
      nzTitle: this.translateService.instant('loginPage.forgotPassTitle'),
      nzContent: ForgotPassComponent,
      nzMaskClosable: false,
      nzClosable:  false,
      nzComponentParams: {},
      nzFooter: [
        {
          label: this.translateService.instant('srt.cancel'),
          shape: 'round',
          onClick: () => modal.destroy()
        },
        {
          label: this.translateService.instant('srt.send'),
          type: 'primary',
          shape: 'round',
          disabled: () => !modal.getContentComponent().forgotPassForm.valid,
          onClick: () => {
            return new Promise (resolve => {
              this.apiService.forgotPass(modal.getContentComponent().forgotPassForm.value.email)
              .then((response) => {
                if (response.code === 0) {
                  this.appService.forgotEmailOK = true;
                  modal.destroy();
                  this.forgotPassOK();
                } else {
                  this.notificationService.create('error',
                    this.translateService.instant('loginPage.forgotPassTitle'),
                    this.translateService.instant('error.errorPassForgotted'));
                }
              }).finally(() => resolve());
            });
          }
        }
      ]
    });
  }

  forgotPassOK() {
    const modal = this.nzModalService.create({
      nzTitle: this.translateService.instant('loginPage.forgotPassTitle'),
      nzContent: ForgotPassComponent,
      nzMaskClosable: false,
      nzClosable:  false,
      nzComponentParams: {},
      nzFooter: [
        {
          label: this.translateService.instant('srt.cancel'),
          shape: 'round',
          onClick: () => modal.destroy()
        },
        {
          label: this.translateService.instant('srt.save'),
          type: 'primary',
          shape: 'round',
          disabled: () => !modal.getContentComponent().forgotPassForm.valid,
          onClick: () => {
            return new Promise (resolve => {
              this.apiService.resetPass(modal.getContentComponent().forgotPassForm.value.code, modal.getContentComponent().forgotPassForm.value.newPass, modal.getContentComponent().forgotPassForm.value.repeatNewPass)
              .then((response) => {
                if (response.code === 0) {
                  this.notificationService.create('success',
                    this.translateService.instant('loginPage.newPassTitle'),
                    this.translateService.instant('success.passUpdated'));
                  modal.destroy();
                  this.credentialsForm.reset();
                } else if (response.code === 1) {
                  this.notificationService.create('error',
                    this.translateService.instant('error.titleError'),
                    this.translateService.instant('error.passDoNotMatch'));
                } else if (response.code === 2) {
                  this.notificationService.create('error',
                    this.translateService.instant('error.titleError'),
                    this.translateService.instant('error.passInUse'));
                } else if (response.code === 6) {
                  this.notificationService.create('error',
                    this.translateService.instant('error.titleError'),
                    this.translateService.instant('error.codeError'));
                }
              }).finally(() => resolve());
            });
          }
        }
      ]
    });
  }
}
