import { Component, OnInit } from '@angular/core';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/services/app.service';
import { ApiService } from 'src/services/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-emotional-state',
  templateUrl: './emotional-state.component.html',
  styleUrls: ['./emotional-state.component.css']
})
export class EmotionalStateComponent implements OnInit {

  dateValue: Date[] | null = null;
  searching = false;
  allData: any;

  constructor(private nzI18nService: NzI18nService,
              private appService: AppService,
              private apiService: ApiService,
              private translateService: TranslateService,
              private notificationService: NzNotificationService) { }

  ngOnInit() { }

  onChange() {
    this.searching = true;
    if (this.dateValue[0] && this.dateValue[1]) {
      this.apiService.getEmotionalState(this.appService.getPatient().privateId,
      this.appService.timestampWithoutMilis(this.appService.startDateHour(this.dateValue[0]).getTime()),
      this.appService.timestampWithoutMilis(this.appService.endDateHour(this.dateValue[1]).getTime()))
      .then((emotionalData: any) => {
        this.allData = emotionalData.emotionalReturn;
        this.searching = false;
      });
    } else {
      this.allData = null;
      this.searching = false;
    }
  }

  getState(idState: number) {
    switch (idState) {
      case 101:
        return this.translateService.instant('emotionalState.veryHappy');
      case 102:
        return this.translateService.instant('emotionalState.happy');
      case 103:
        return this.translateService.instant('emotionalState.normal');
      case 104:
        return this.translateService.instant('emotionalState.sad');
      case 105:
        return this.translateService.instant('emotionalState.verySad');
    }
  }
}
