import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/services/api.service';
import { AppService } from 'src/services/app.service';
import { DisabledDateFn } from 'ng-zorro-antd/date-picker/standard-types';

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.css']
})
export class PrescriptionListComponent implements OnInit {

  usingDates = [];
  usedDates = [];

  constructor(private router: Router,
              private apiService: ApiService,
              private appService: AppService,
              private translateService: TranslateService,
              private notificationService: NzNotificationService,
              private nzI18nService: NzI18nService) { }

  ngOnInit(): void {}

  newPrescriptionPage(): any {
    this.router.navigate(['/treatmentPage']);
  }

  addRow(prescription: any): void {
    prescription.lithemia.push({
      token: this.appService.getUser().token,
      privateId: this.appService.getPatient().privateId,
      prescriptionId: prescription.prescriptionId,
      date: null,
      realLithemia: 0.0,
      editing: true
    });
    prescription.lithemia = [...prescription.lithemia];
  }

  save(lithemia: any): void {
    if (lithemia.realLithemia !== 0 && lithemia.date != null) {
      const newLithemia = {
        token: lithemia.token,
        privateId: lithemia.privateId,
        prescriptionId: lithemia.prescriptionId,
        date: this.appService.timestampWithoutMilis(lithemia.date),
        value: lithemia.realLithemia
      };

      this.apiService.saveLithemia(newLithemia).then(data => {
        if (data.code === 0) {
          this.notificationService.create('success', this.translateService.instant('srt.save'), this.translateService.instant('success.successSaveLithemia'));
          this.apiService.getPatient(this.appService.getPatient().privateId).then(patientData => {
            this.appService.setPatient(patientData);
            this.appService.launchUpdateChart();
          });
        } else {
          this.notificationService.create('error', this.translateService.instant('error.saveError'), this.translateService.instant('error.errorSaveLithemia'));
        }
      });
    } else {
      this.notificationService.create('error', this.translateService.instant('error.validateError'), this.translateService.instant('error.incorrectLithemia'));
    }
  }

  dontSave(prescription: any): void {
    prescription.lithemia = prescription.lithemia.slice(0, prescription.lithemia.length - 1);
  }

  hasEditingLithemia(prescription: any): boolean {
    if (prescription.lithemia != null) {
      return prescription.lithemia.filter(l => l.editing).length !== 0;
    } else {
      prescription.lithemia = [];
      return prescription.lithemia.length === 0;
    }
  }

  disabledDate(prescription: any): DisabledDateFn {
    this.usingDates = prescription.lithemia.map(l => new Date(l.date).toDateString()).slice(0, prescription.lithemia.length - 1);
    this.usedDates = prescription.lithemia.map(l => new Date(this.appService.timestampWithMilis(l.date)).toDateString()).slice(0, prescription.lithemia.length - 1);

    return (current: Date): boolean => {
      return this.usingDates.indexOf(current.toDateString()) !== -1 || this.usedDates.indexOf(current.toDateString()) !== -1 || this.appService.disabledFutureDate(current);
    };
  }
}
