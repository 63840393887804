import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/services/app.service';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-treatment-page',
  templateUrl: './treatment-page.component.html',
  styleUrls: ['./treatment-page.component.css']
})
export class TreatmentPageComponent implements OnInit {

  simulation = false;
  loading = false;

  prescription: any = {
    token: this.appService.getUser().token,
    privateId: this.appService.getPatient().privateId,
    weight: this.appService.minWeight,
    height: this.appService.minHeight,
    sleepingHours: '0',
    physicalActivity: 0,
    intensityActivity: '2',
    adition1: false,
    adition2: false,
    predictedLithemia: 0,
    predictedDose: 0,
    realDose: this.appService.minDose,
    realLithemia: 0
  };

  constructor(private appService: AppService,
              private apiService: ApiService,
              private router: Router,
              private translateService: TranslateService,
              private notificationService: NzNotificationService) { }

  ngOnInit() {
    // Cargar datos de la última prescripción del paciente
    if (this.appService.getPatient().prescriptionList != null && this.appService.getPatient().prescriptionList.length > 0) {
      const lastPrescription = this.appService.getPatient().prescriptionList[0];
      this.prescription.weight = lastPrescription.weight;
      this.prescription.height = lastPrescription.height;
      this.prescription.sleepingHours = lastPrescription.sleepingHours.toString();
      this.prescription.physicalActivity = lastPrescription.physicalActivity;
      this.prescription.intensityActivity = lastPrescription.intensityActivity.toString();
      this.prescription.adition1 = lastPrescription.alcohol === 1;
      this.prescription.adition2 = lastPrescription.drug === 1;
    }

    // Obtener estimación
    this.onChangePrescription();
  }

  onChangePrescription() {
    this.loading = true;

    const newPrescription = this.appService.changePrescription(this.prescription);

    this.apiService.getPrescriptionPredict(newPrescription)
      .then(data => {
        if (data) {
          this.prescription.predictedLithemia = data.predictedLithemia;
          this.prescription.predictedDose = data.predictedDose;
        }
      }).finally(() => {
        this.loading = false;
      });
  }

  goToPatientPage() {
    this.router.navigate(['/patientPage']);
  }

  getPredictedPillColor(part: number): string {
    return this.appService.getPillColor(this.prescription.predictedDose, '#7b7a7a', part);
  }
}
