import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './app.service';
import { Router } from '@angular/router';
import { Subscription ,Observable, Subject, timer } from 'rxjs';
import { takeUntil, startWith ,switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  timer: number = null;
  sessionAllowTime = environment.session_timer_milis / 1000;
  rxjsTimer = timer(1000, 1000);
  resetTimer = new Subject();
  setTimer = true;
  subTimer: Subscription;

  constructor(
    private http: HttpClient,
    private notificationService: NzNotificationService,
    private translateService: TranslateService,
    private appService: AppService,
    private router: Router) { }

  private sessionControl(): void {
    this.setTimer = false;
    this.subTimer =  this.resetTimer.pipe(
      startWith(void 0),
      switchMap(() => timer(1000, 1000))
    ).subscribe(val => {
      this.timer = val;
      if (this.timer >= this.sessionAllowTime) {
        this.subTimer.unsubscribe();
        this.timer = null;
        this.setTimer = true;
        this.resetTimer = new Subject();
        this.appService.deleteUser();
        this.router.navigate(['/login']);
        this.notificationService.remove();
        this.notificationService.create('warning', this.translateService.instant('srt.notification'), this.translateService.instant('error.sessionExpired'));
      }
    })
  }

  private evalueSetReset(): void {
    if(this.timer === null || this.timer === undefined){
      if(this.setTimer) {
        this.sessionControl()
      }
    } else {
      this.resetTimer.next(void 0);
    }
  }

  private makeRequest(method, path, data): any {
    this.evalueSetReset();
    return new Promise((resolve, reject) => {
      // Llamada solicitada
      this.http.request(method, environment.api_endpoint + path, {body: data}).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error: any) => {
          if (error.status === environment.session_expired_code) {
            // Llamada para actualizar token
            this.http.request('POST', environment.api_endpoint + '/refreshToken', {body: {token: this.appService.getUser().token}}).subscribe(
              (resRefresh: any) => {
                // Guardar nuevo token
                const user = this.appService.getUser();
                user.token = resRefresh.token;
                this.appService.setUser(user);

                if (data.hasOwnProperty('token')) {
                  data.token = resRefresh.token;
                }
                // Rellamada solicitada
                this.http.request(method, environment.api_endpoint + path, {body: data}).subscribe(
                  (resRellamada: any) => {
                    resolve(resRellamada);
                  },
                  (errorRellamada: any) => {
                    this.notificationService.create('error',
                      this.translateService.instant('error.titleError'),
                      this.translateService.instant('error.bodyApiError'));
                    console.log('Error Rellamada: ' + JSON.stringify(errorRellamada));
                    reject(errorRellamada);
                  }
                );
              },
              (errorRefresh: any) => {
                this.notificationService.create('error',
                  this.translateService.instant('error.titleError'),
                  this.translateService.instant('error.bodyApiError'));
                console.log('Error refresh: ' + JSON.stringify(errorRefresh));
                reject(errorRefresh);
              }
            );
          } else {
            this.notificationService.create('error',
              this.translateService.instant('error.titleError'),
              this.translateService.instant('error.bodyApiError'));
            console.log('Error makeRequest: ' + JSON.stringify(error));
            reject(error);
          }
        }
      );
    });
  }

  login(userEmail: string, userPassword: string) {
    return this.makeRequest('POST', '/login', {email: userEmail, password: userPassword});
  }

  forgotPass(userEmail: string) {
    return this.makeRequest('POST', '/login/forgotPass', {email: userEmail});
  }

  resetPass(userEmail: string, newPass: string, newPass2: string) {
    return this.makeRequest('POST', '/login/resetPass', {emailToken: userEmail, newPassword1: newPass, newPassword2: newPass2});
  }

  updatePass(userEmail: string, oldPass: string, newPass: string, newPass2: string) {
    return this.makeRequest('POST', '/login/updatePass', {email: userEmail, oldPassword: oldPass, newPassword1: newPass, newPassword2: newPass2});
  }

  smsCode(doctorToken: string, smsCode: number) {
    return this.makeRequest('POST', '/login/sms', {token: doctorToken, code: smsCode});
  }

  getPatients() {
    return this.makeRequest('POST', '/patient/all', {token: this.appService.getUser().token});
  }

  getPatient(id: string) {
    return this.makeRequest('POST', '/patient/get', {token: this.appService.getUser().token, privateId: id});
  }

  savePatient(patientForm: any) {
    return this.makeRequest('POST', '/patient/save', patientForm);
  }

  getPatientActivity(id: string, startDate: number, endDate: number) {
    return this.makeRequest('POST', '/patient/activity', {token: this.appService.getUser().token, privateId: id, dateStart: startDate, dateEnd: endDate});
  }

  getEmotionalState(id: string, startDate: number, endDate: number) {
    return this.makeRequest('POST', '/patient/emotionalState', {token: this.appService.getUser().token, privateId: id, dateStart: startDate, dateEnd: endDate});
  }

  getPrescriptionPredict(prescription: any) {
    return this.makeRequest('POST', '/prescription/predict', prescription);
  }

  getLithemiaPredict(prescription: any) {
    return this.makeRequest('POST', '/lithemia/predict', prescription);
  }

  savePrescription(prescription: any) {
    return this.makeRequest('POST', '/prescription/save', prescription);
  }

  saveLithemia(lithemia: any) {
    return this.makeRequest('POST', '/lithemia/save', lithemia);
  }
}
