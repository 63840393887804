import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/services/app.service';
import { ApiService } from 'src/services/api.service';
import { AlarmListComponent } from 'src/app/header/alarm-list/alarm-list.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { TranslateService } from '@ngx-translate/core';
import { UpdatePassComponent } from 'src/app/header/update-pass/update-pass.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  isHomePage: boolean;
  patiensWithAlarms: any;

  constructor(private router: Router,
              private drawerService: NzDrawerService,
              private translateService: TranslateService,
              private notificationService: NzNotificationService,
              private appService: AppService,
              private nzModalService: NzModalService,
              private apiService: ApiService) { }

  ngOnInit() {
    this.isHomePage = this.router.url.indexOf('homePage') !== -1;

    if (this.isHomePage) {
      this.apiService.getPatients().then(patiensWithAlarms => {
        this.patiensWithAlarms = patiensWithAlarms;
      });
    }
  }

  newPatientPage(): any {
    this.appService.setPatient(null);
    this.router.navigate(['/patientPage']);
  }

  logOut(): void {
    this.appService.deleteUser();
    this.router.navigate(['/login']);
  }

  showAlarms(): void {
    const title = this.isHomePage ?
                    this.translateService.instant('header.alarmList') :
                    this.translateService.instant('header.patientAlarms', {patientName: this.appService.getPatientName(this.appService.getPatient())});
    const data = this.isHomePage ? this.patiensWithAlarms : null;
    const drawerRef = this.drawerService.create<AlarmListComponent, {}, string>({
      nzTitle: title,
      nzContent: AlarmListComponent,
      nzWidth: 450,
      nzContentParams: {patientsWithAlarms: data},
      nzPlacement: 'left'
    });
    this.appService.setDrawer(drawerRef);
  }

  getAlarmsCount(): number {
    let count = 0;
    if (this.isHomePage && this.patiensWithAlarms != null) {
      this.patiensWithAlarms.forEach(patient => {
        count += patient.alarmTypes.length;
      });
    } else if (this.appService.getPatient() != null) {
      count = this.appService.getPatient().alarmTypes.length;
    }
    return count;
  }

  updatePass(): void {
    const modal = this.nzModalService.create({
      nzTitle: this.translateService.instant('loginPage.newPassTitle'),
      nzContent: UpdatePassComponent,
      nzMaskClosable: false,
      nzClosable:  false,
      nzComponentParams: {},
      nzFooter: [
        {
          label: this.translateService.instant('srt.cancel'),
          shape: 'round',
          onClick: () => modal.destroy()
        },
        {
          label: this.translateService.instant('srt.save'),
          type: 'primary',
          shape: 'round',
          disabled: () => !modal.getContentComponent().updatePassForm.valid,
          onClick: () => {
            return new Promise (resolve => {
              this.apiService.updatePass(modal.getContentComponent().updatePassForm.value.email, modal.getContentComponent().updatePassForm.value.oldPass,
                    modal.getContentComponent().updatePassForm.value.newPass, modal.getContentComponent().updatePassForm.value.newPass2)
              .then((response) => {
                if (response.code === 0) {
                  this.notificationService.create('success',
                    this.translateService.instant('loginPage.newPassTitle'),
                    this.translateService.instant('success.passUpdated'));
                  modal.destroy();
                } else if (response.code === 1) {
                  this.notificationService.create('error',
                    this.translateService.instant('error.titleError'),
                    this.translateService.instant('error.passDoNotMatch'));
                } else if (response.code === 2) {
                  this.notificationService.create('error',
                    this.translateService.instant('error.titleError'),
                    this.translateService.instant('error.passInUse'));
                } else if (response.code === 3) {
                  this.notificationService.create('error',
                    this.translateService.instant('error.titleError'),
                    this.translateService.instant('error.passNotMatchWithPrevious'));
                } else if (response.code === 4) {
                  this.notificationService.create('error',
                    this.translateService.instant('error.titleError'),
                    this.translateService.instant('error.newEqualsToOld'));
                }
              }).finally(() => resolve());
            });
          }
        }
      ]
    });
  }
}
