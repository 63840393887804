export const dateLocaleEU = [
    'eu',
    [
        ['a. m.', 'p. m.'],
        null,
        null
    ],
    null,
    [
        ['I', 'A', 'A', 'A', 'O', 'O', 'L'],
        ['Ig.', 'Al.', 'Ar.', 'Az.', 'Og.', 'Or.', 'Lr.'],
        ['Igandea', 'Astelehena', 'Asteartea', 'Asteazkena', 'Osteguna', 'Ostirala', 'Larunbata'],
        ['IG', 'AL', 'AR', 'AZ', 'OG', 'OR', 'LR']
    ],
    null,
    [
        ['U', 'O', 'M', 'A', 'M', 'E', 'U', 'A', 'I', 'U', 'A', 'A'],
        ['Urt.', 'Ots.', 'Mar.', 'Api.', 'Mai.', 'Eka.', 'Uzt.', 'Abu.', 'Ira.', 'Urr.', 'Aza.', 'Abe.'],
        ['Urtarrila', 'Otsaila', 'Martxoa', 'Apirila', 'Maiatza', 'Ekaina', 'Uztaila', 'Abuztua', 'Iraila', 'Urria', 'Azaroa', 'Abendua']
    ],
    null,
    [
        ['K. a.', 'K. o.'],
        null,
        ['Kristo aurretik', 'Kristo ondoren']
    ],
    1,
    [6, 0],
    ['yy/M/d', 'y MMM d', 'y(\'e\')\'ko\' MMMM\'ren\' d(\'a\')', 'y(\'e\')\'ko\' MMMM\'ren\' d(\'a\'), EEEE'],
    ['HH:mm', 'HH:mm:ss', 'HH:mm:ss (z)', 'HH:mm:ss (zzzz)'],
    ['{1} {0}', null, '{1}, {0}', null],
    [',', '.', ';', '%', '+', '−', 'E', '×', '‰', '∞', 'NaN', ':'],
    ['#,##0.###', '% #,##0', '#,##0.00 ¤', '#E0'],
    '€',
    'euroa', {
        AUD: [null, '$'],
        BRL: [null, 'R$'],
        CNY: [null, '¥'],
        EGP: [],
        ESP: ['₧'],
        GBP: [null, '£'],
        HKD: [null, '$'],
        ILS: [null, '₪'],
        INR: [null, '₹'],
        JPY: [null, '¥'],
        KRW: [null, '₩'],
        MXN: [null, '$'],
        NZD: [null, '$'],
        RON: [null, 'L'],
        THB: ['฿'],
        TWD: [null, 'NT$'],
        USD: ['US$', '$'],
        XAF: [],
        XCD: [null, '$'],
        XOF: []
    },
    null
];
