export const ngZorroES = {
    locale: 'es',
    Pagination: {
        items_per_page: '/ página',
        jump_to: 'Ir a',
        jump_to_confirm: 'confirmar',
        page: '',
        prev_page: 'Página anterior',
        next_page: 'Página siguiente',
        prev_5: '5 páginas previas',
        next_5: '5 páginas siguientes',
        prev_3: '3 páginas previas',
        next_3: '3 páginas siguientes'
    },
    DatePicker: {
        lang: {
            placeholder: 'Seleccionar fecha',
            rangePlaceholder: ['Fecha inicial', 'Fecha final'],
            today: 'Hoy',
            now: 'Ahora',
            backToToday: 'Volver a hoy',
            ok: 'Aceptar',
            clear: 'Limpiar',
            month: 'Mes',
            year: 'Año',
            timeSelect: 'Seleccionar hora',
            dateSelect: 'Seleccionar fecha',
            monthSelect: 'Elegir un mes',
            yearSelect: 'Elegir un año',
            decadeSelect: 'Elegir una década',
            yearFormat: 'yyyy',
            dateFormat: 'dd/MM/yyyy',
            dayFormat: 'dd',
            dateTimeFormat: 'dd/MM/yyyy HH:mm',
            monthBeforeYear: true,
            previousMonth: 'Mes anterior (PageUp)',
            nextMonth: 'Mes siguiente (PageDown)',
            previousYear: 'Año anterior (Control + left)',
            nextYear: 'Año siguiente (Control + right)',
            previousDecade: 'Década anterior',
            nextDecade: 'Década siguiente',
            previousCentury: 'Siglo anterior',
            nextCentury: 'Siglo siguiente'
        },
        timePickerLocale: {
            placeholder: 'Seleccionar hora'
        }
    },
    TimePicker: {
        placeholder: 'Seleccionar hora'
    },
    Calendar: {
        today: 'Hoy',
        now: 'Ahora',
        backToToday: 'Volver a hoy',
        ok: 'Aceptar',
        clear: 'Limpiar',
        month: 'Mes',
        year: 'Año',
        timeSelect: 'Seleccionar hora',
        dateSelect: 'Seleccionar fecha',
        monthSelect: 'Elegir un mes',
        yearSelect: 'Elegir un año',
        decadeSelect: 'Elegir una década',
        yearFormat: 'yyyy',
        dateFormat: 'dd/MM/yyyy',
        dayFormat: 'dd',
        dateTimeFormat: 'dd/MM/yyyy HH:mm',
        monthBeforeYear: true,
        previousMonth: 'Mes anterior (PageUp)',
        nextMonth: 'Mes siguiente (PageDown)',
        previousYear: 'Año anterior (Control + left)',
        nextYear: 'Año siguiente (Control + right)',
        previousDecade: 'Década anterior',
        nextDecade: 'Década siguiente',
        previousCentury: 'Siglo anterior',
        nextCentury: 'Siglo siguiente'
    },
    global: {
        placeholder: 'Seleccione'
    },
    Table: {
        filterTitle: 'Filtrar menú',
        filterConfirm: 'Aceptar',
        filterReset: 'Reiniciar',
        selectAll: 'Seleccionar todo',
        selectInvert: 'Invertir selección',
        sortTitle: 'Ordenar'
    },
    Modal: {
        okText: 'Aceptar',
        cancelText: 'Cancelar',
        justOkText: 'Aceptar'
    },
    Popconfirm: {
        okText: 'Aceptar',
        cancelText: 'Cancelar'
    },
    Transfer: {
        searchPlaceholder: 'Buscar aquí',
        itemUnit: 'elemento',
        itemsUnit: 'elementos'
    },
    Upload: {
        uploading: 'Subiendo...',
        removeFile: 'Eliminar archivo',
        uploadError: 'Error al subir el archivo',
        previewFile: 'Vista previa'
    },
    Empty: {
        description: 'No hay datos'
    },
    Icon: {
        icon: 'ícono'
    },
    Text: {
        edit: 'editar',
        copy: 'copiar',
        copied: 'copiado',
        expand: 'expandir'
    },
    PageHeader: {
        back: 'volver'
    }
};
