import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from 'src/services/app.service';
import { ApiService } from 'src/services/api.service';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activity-band',
  templateUrl: './activity-band.component.html',
  styleUrls: ['./activity-band.component.css']
})
export class ActivityBandComponent implements OnInit, OnDestroy {

  dateValue: Date[] | null = null;
  chartType = null;
  searching = false;
  allActivityData: any = [];
  loadedActivityData: any = [];

  colorScheme = {
    domain: ['#040087']
  };

  updateChartSubscription: Subscription;

  constructor(private nzI18nService: NzI18nService,
              private translateService: TranslateService,
              private appService: AppService,
              private apiService: ApiService,
              private notificationService: NzNotificationService) { }

  ngOnInit() {
    this.updateChartSubscription = this.appService.updateChartSubscription().subscribe(() => {
      this.onChangeChart();
    });
  }

  ngOnDestroy() {
    if (this.updateChartSubscription) {
      this.updateChartSubscription.unsubscribe();
    }
  }

  onChangeDate() {
    this.searching = true;
    if (this.dateValue != null && this.dateValue[0] && this.dateValue[1]) {
      this.apiService.getPatientActivity(this.appService.getPatient().privateId,
      this.appService.timestampWithoutMilis(this.appService.startDateHour(this.dateValue[0]).getTime()),
      this.appService.timestampWithoutMilis(this.appService.endDateHour(this.dateValue[1]).getTime()))
      .then(allActivityData => {
        this.allActivityData = allActivityData;
        this.searching = false;
        this.chartType = 'steps';
        this.onChangeChart();
      });
    } else {
      this.chartType = null;
      this.allActivityData = null;
      this.searching = false;
    }
  }

  onChangeChart() {
    const data = [];
    if (this.chartType === 'steps') {
      this.allActivityData.activityBandData.forEach(element => {
        data.push({name: this.appService.dateFormat(element.date), value: element.steps});
      });
    } else if (this.chartType === 'dream') {
      this.allActivityData.activityBandData.forEach(element => {
        data.push({name: this.appService.dateFormat(element.date), value: this.convertToHHMM(element.sleepingHours)});
      });
    } else if (this.chartType === 'calories') {
      this.allActivityData.activityBandData.forEach(element => {
        data.push({name: this.appService.dateFormat(element.date), value: element.calories});
      });
    }

    this.loadedActivityData = [{
      name: this.getYAxisLabel(),
      series: data
    }];
  }

  getYAxisLabel(): string {
    let literal = 'activityBand.calories';

    if (this.chartType === 'steps') {
      literal = 'activityBand.nSteps';
    } else if (this.chartType === 'dream') {
      literal = 'activityBand.hSleep';
    }

    return this.translateService.instant(literal);
  }

  convertToHHMM(num) {
    const hours = Math.floor(num / 60);
    const minutes = Math.round(((num / 60) - hours) * 60);
    return hours + '.' + minutes;
  }

  getFormat(value) {
    if (this.chartType === 'dream') {
      const values = String(value).split('.');
      values[1] = ('0' + values[1]).substr(-2);
      return values.join(' :');
    } else {
      return String(value).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.');
    }
  }
}
