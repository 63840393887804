import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* LOGIN PAGE */
import { LoginComponent } from '../app/login-page/login.component';
import { SmsComponent } from '../app/login-page/sms/sms.component';

/* HOME PAGE */
import { HomePageComponent } from '../app/home-page/home-page.component';

/* PATIENT PAGE */
import { PatientPageComponent } from '../app/patient-page/patient-page.component';
import { PrescriptionListComponent } from '../app/patient-page/prescription-list/prescription-list.component';
import { TreatmentChartComponent } from '../app/patient-page/treatment-chart/treatment-chart.component';
import { ActivityBandComponent } from '../app/patient-page/activity-band/activity-band.component';
import { EmotionalStateComponent } from '../app/patient-page/emotional-state/emotional-state.component';

/* TREATMENT PAGE */
import { TreatmentPageComponent } from '../app/treatment-page/treatment-page.component';
import { PrescriptionPageComponent } from '../app/treatment-page/prescription-page/prescription-page.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent, children: [
      { path: 'sms', component: SmsComponent }]
  },
  { path: 'homePage', component: HomePageComponent },
  { path: 'patientPage', component: PatientPageComponent , children: [
      { path: '', pathMatch: 'full', redirectTo: 'treatmentChart' },
      { path: 'treatmentChart', component: TreatmentChartComponent },
      { path: 'activityBand', component: ActivityBandComponent },
      { path: 'emotionalState', component: EmotionalStateComponent }]
  },
  { path: 'treatmentPage', component: TreatmentPageComponent, children: [
      { path: 'prescriptionPage', component: PrescriptionPageComponent }]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
