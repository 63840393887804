import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/services/app.service';

@Component({
  selector: 'app-treatment-chart',
  templateUrl: './treatment-chart.component.html',
  styleUrls: ['./treatment-chart.component.css']
})
export class TreatmentChartComponent implements OnInit, OnDestroy {

  prescriptionsChart: any[] = [];
  lithemiasChart: any[] = [];

  colorScheme = {
    domain: ['#040087', '#e4776a']
  };

  updateChartSubscription: Subscription;

  constructor(private translateService: TranslateService,
              private appService: AppService) { }

  ngOnInit() {
    this.updateChartSubscription = this.appService.updateChartSubscription().subscribe(() => {
      this.getChartsData();
    });
  }

  ngOnDestroy() {
    if (this.updateChartSubscription) {
      this.updateChartSubscription.unsubscribe();
    }
  }

  getChartsData() {
    // init
    this.prescriptionsChart = [
      {name: this.translateService.instant('treatmentPage.predictedDose'), series: []},
      {name: this.translateService.instant('treatmentPage.prescDose'), series: []}
    ];
    this.lithemiasChart = [
      {name: this.translateService.instant('treatmentPage.realLithemia'), series: []},
      {name: this.translateService.instant('treatmentPage.estimatedLithemia'), series: []}
    ];
    
    if (this.appService.getPatient().prescriptionList != null && this.appService.getPatient().prescriptionList.length !== 0) {
      const prescriptionListAux = [...this.appService.getPatient().prescriptionList].sort((a, b) => a.date > b.date ? 1 : a.date < b.date ? -1 : 0);
      prescriptionListAux.forEach(prescription => {
        // prescription data
        this.prescriptionsChart[1].series.push({
          name: this.appService.dateFormat(prescription.date),
          value: prescription.realDose,
          weight: prescription.weight,
          height: prescription.height,
          sleepingHours: prescription.sleepingHours
        });

        // estimated data
        this.prescriptionsChart[0].series.push({
          name: this.appService.dateFormat(prescription.date),
          value: prescription.predictedDose,
          weight: prescription.weight,
          height: prescription.height,
          sleepingHours: prescription.sleepingHours
        });

        if (prescription.lithemia != null && prescription.lithemia.length !== 0) {
          const lithemiaAux = [...prescription.lithemia].sort((a, b) => a.date > b.date ? 1 : a.date < b.date ? -1 : 0);
          lithemiaAux.forEach(lithemia => {

            // real lithemia data
            this.lithemiasChart[0].series.push({
              name: this.appService.dateFormat(lithemia.date),
              value: lithemia.realLithemia,
              date: lithemia.date
            });

            // estimated lithemia data
            this.lithemiasChart[1].series.push({
              name: this.appService.dateFormat(lithemia.date),
              value: prescription.predictedLithemia,
              date: lithemia.date
            });
          });
        }
      });

      // Ordenación
      this.lithemiasChart[0].series.sort((a, b) => a.date > b.date ? 1 : a.date < b.date ? -1 : 0);
      this.lithemiasChart[1].series.sort((a, b) => a.date > b.date ? 1 : a.date < b.date ? -1 : 0);
    }
  }
}
