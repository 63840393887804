export const ngZorroCA = {
    locale: 'ca',
    Pagination: {
        items_per_page: '_/ pàgina',
        jump_to: 'Anar a',
        jump_to_confirm: 'confirmar',
        page: '',
        prev_page: 'Pàgina anterior',
        next_page: 'Pàgina següent',
        prev_5: '5 pàginas previes',
        next_5: '5 pàginas següents',
        prev_3: '3 pàgines prèvies',
        next_3: '3 pàgines següents'
    },
    DatePicker: {
        lang: {
            placeholder: 'Seleccionar data',
            rangePlaceholder: ['Data inicial', 'Data final'],
            today: 'Avui',
            now: 'Ara',
            backToToday: 'Tornar a avui',
            ok: 'Acceptar',
            clear: 'Netejar',
            month: 'Mes',
            year: 'Any',
            timeSelect: 'Seleccionar hora',
            dateSelect: 'Seleccionar data',
            monthSelect: 'Triar un mes',
            yearSelect: 'Triar un any',
            decadeSelect: 'Triar una dècada',
            yearFormat: 'yyyy',
            dateFormat: 'dd/MM/yyyy',
            dayFormat: 'dd',
            dateTimeFormat: 'dd/MM/yyyy HH:mm',
            monthBeforeYear: true,
            previousMonth: 'CA_Mes anterior (PageUp)',
            nextMonth: 'CA_Mes siguiente (PageDown)',
            previousYear: 'CA_Año anterior (Control + left)',
            nextYear: 'CA_Año siguiente (Control + right)',
            previousDecade: 'CA_Década anterior',
            nextDecade: 'CA_Década siguiente',
            previousCentury: 'CA_Siglo anterior',
            nextCentury: 'CA_Siglo siguiente'
        },
        timePickerLocale: {
            placeholder: 'CA_Seleccionar hora'
        }
    },
    TimePicker: {
        placeholder: 'CA_Seleccionar hora'
    },
    Calendar: {
        today: 'CA_Hoy',
        now: 'CA_Ahora',
        backToToday: 'CA_Volver a hoy',
        ok: 'CA_Aceptar',
        clear: 'CA_Limpiar',
        month: 'CA_Mes',
        year: 'CA_Año',
        timeSelect: 'CA_Seleccionar hora',
        dateSelect: 'CA_Seleccionar fecha',
        monthSelect: 'CA_Elegir un mes',
        yearSelect: 'CA_Elegir un año',
        decadeSelect: 'CA_Elegir una década',
        yearFormat: 'yyyy',
        dateFormat: 'dd/MM/yyyy',
        dayFormat: 'dd',
        dateTimeFormat: 'dd/MM/yyyy HH:mm',
        monthBeforeYear: true,
        previousMonth: 'Mes anterior (PageUp)',
        nextMonth: 'Mes següent (PageDown)',
        previousYear: 'A anterior (Control + left)',
        nextYear: 'Any següent (Control + right)',
        previousDecade: 'Dècada anterior',
        nextDecade: 'Dècada següent',
        previousCentury: 'Segle anterior',
        nextCentury: 'Segle següent'
    },
    global: {
        placeholder: 'Seleccioneu'
    },
    Table: {
        filterTitle: 'Filtrar menú',
        filterConfirm: 'Acceptar',
        filterReset: 'Reiniciar',
        selectAll: 'Selecciona-ho tot',
        selectInvert: 'Inverteix la selecció',
        sortTitle: 'Ordenar'
    },
    Modal: {
        okText: 'Acceptar',
        cancelText: 'Cancel·lar',
        justOkText: 'Acceptar'
    },
    Popconfirm: {
        okText: 'Acceptar',
        cancelText: 'Cancel·lar'
    },
    Transfer: {
        searchPlaceholder: 'Buscar aquí',
        itemUnit: 'element',
        itemsUnit: 'elements'
    },
    Upload: {
        uploading: 'Pujant...',
        removeFile: 'Eliminar archiu',
        uploadError: 'CA_Error al pujar l´archiu',
        previewFile: 'Vista prèvia'
    },
    Empty: {
        description: 'No hi ha dades'
    },
    Icon: {
        icon: 'icona'
    },
    Text: {
        edit: 'editar',
        copy: 'copiar',
        copied: 'copiat',
        expand: 'expandir'
    },
    PageHeader: {
        back: 'tornar'
    }
};
