import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/services/app.service';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.css']
})
export class ForgotPassComponent implements OnInit {

  forgotPassForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService) {}

  ngOnInit() {
    if (!this.appService.forgotEmailOK) {
      this.forgotPassForm = this.formBuilder.group({
        email: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getEmailPattern())])
      });
    } else {
      this.forgotPassForm = this.formBuilder.group({
        code: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getCodePattern())]),
        newPass: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getPasswordPattern())]),
        repeatNewPass: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getPasswordPattern())])
      });
    }
  }
}
