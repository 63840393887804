export const dateLocaleES = [
    'es',
    [
        ['a. m.', 'p. m.'],
        null,
        null
    ],
    null,
    [
        ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        ['Dom.', 'Lun.', 'Mar.', 'Mié.', 'Jue.', 'Vie.', 'Sáb.'],
        ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        ['DO', 'LU', 'MA', 'MI', 'JU', 'VI', 'SA']
    ],
    null,
    [
        ['E', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
        ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sept.', 'Oct.', 'Nov.', 'Dic.'],
        ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    ],
    null,
    [
        ['a. C.', 'd. C.'],
        null,
        ['antes de Cristo', 'después de Cristo']
    ],
    1,
    [6, 0],
    ['d/M/yy', 'd MMM y', 'd \'de\' MMMM \'de\' y', 'EEEE, d \'de\' MMMM \'de\' y'],
    ['HH:mm', 'HH:mm:ss', 'HH:mm:ss z', 'HH:mm:ss (zzzz)'],
    ['{1} {0}', null, '{1}, {0}', null],
    [',', '.', ';', '%', '+', '-', 'E', '×', '‰', '∞', 'NaN', ':'],
    ['#,##0.###', '#,##0 %', '#,##0.00 ¤', '#E0'],
    '€',
    'euro',
    {
        AUD: [null, '$'],
        BRL: [null, 'R$'],
        CNY: [null, '¥'],
        EGP: [],
        ESP: ['₧'],
        GBP: [null, '£'],
        HKD: [null, '$'],
        ILS: [null, '₪'],
        INR: [null, '₹'],
        JPY: [null, '¥'],
        KRW: [null, '₩'],
        MXN: [null, '$'],
        NZD: [null, '$'],
        RON: [null, 'L'],
        THB: ['฿'],
        TWD: [null, 'NT$'],
        USD: ['US$', '$'],
        XAF: [],
        XCD: [null, '$'],
        XOF: []
    },
    null
];
