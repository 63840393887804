import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { ApiService } from 'src/services/api.service';
import { AppService } from 'src/services/app.service';

@Component({
  selector: 'app-patient-page',
  templateUrl: './patient-page.component.html',
  styleUrls: ['./patient-page.component.css']
})
export class PatientPageComponent implements OnInit {

  isSaving = false;
  editPatient: boolean = this.appService.getPatient() == null;
  patientForm: FormGroup;

  constructor(private apiService: ApiService,
              private appService: AppService,
              private translateService: TranslateService,
              private router: Router,
              private formBuilder: FormBuilder,
              private notificationService: NzNotificationService,
              private nzI18nService: NzI18nService) {
                this.patientForm = this.formBuilder.group({
                  token: new FormControl(this.appService.getUser().token),
                  privateId: new FormControl(''),
                  publicId: new FormControl(null, Validators.required),
                  nif: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getNIFPattern())]),
                  name: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getNamePattern())]),
                  surname1: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getNamePattern())]),
                  surname2: new FormControl('', [Validators.pattern(this.appService.getNamePattern())]),
                  sex: new FormControl(null, Validators.required),
                  birthdate: new FormControl(null, Validators.required),
                  email: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getEmailPattern())]),
                  telephone: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getTelephonePattern())])
                });
              }

  ngOnInit() {
    this.reloadFormData();
  }

  savePatient(): void {
    this.isSaving = true;

    // Validar los campos del formulario
    Object.keys(this.patientForm.controls).forEach(key => {
      this.patientForm.controls[key].markAsDirty();
      this.patientForm.controls[key].updateValueAndValidity();
    });

    // Comprobar estado del formulario
    if (this.patientForm.valid) {
      const patient = this.patientForm.value;
      patient.birthdate = this.appService.timestampWithoutMilis(patient.birthdate);
      this.apiService.savePatient(patient)
      .then(data => {
          if (data.code === 0) { // MODIFICACIÓN OK
            this.notificationService.create('success', this.translateService.instant('srt.modify'), this.translateService.instant('success.successModifyPatient'));
            this.updateData();
          } else if (data.code === 1) { // ALTA OK
            this.notificationService.create('success', this.translateService.instant('srt.save'), this.translateService.instant('success.successSavePatient'));
            this.goToHomePage();
          } else if (data.code === 5) { // EMAIL EN USO
            this.notificationService.create('error', this.translateService.instant('error.saveError'), this.translateService.instant('error.emailInUse'));
          } else { // ERROR
            this.notificationService.create('error', this.translateService.instant('error.saveError'), this.translateService.instant('error.savePatientError'));
          }
        })
      .finally(() => {
        this.editPatient = false;
        this.isSaving = false;
      });
    } else {
      this.isSaving = false;
      this.notificationService.create('error', this.translateService.instant('error.validateError'), this.translateService.instant('error.incorrectForm'));
    }
  }

  goToHomePage() {
    this.router.navigate(['/homePage']);
  }

  cancel() {
    if (this.appService.getPatient() == null) {
      // si cancelamos en un alta, redirigimos al homePage
      this.goToHomePage();
    } else {
      // si cancelamos en modo edición, volvemos a la vista del paciente
      this.editPatient = false;
      this.reloadFormData();
    }
  }

  reloadFormData(): void {
    const patient = this.appService.getPatient();

    if (patient != null) {
      this.patientForm.controls.token.setValue(this.appService.getUser().token);
      this.patientForm.controls.privateId.setValue(patient.privateId);
      this.patientForm.controls.publicId.setValue(patient.publicId);
      this.patientForm.controls.nif.setValue(patient.nif);
      this.patientForm.controls.name.setValue(patient.name);
      this.patientForm.controls.surname1.setValue(patient.surname1);
      this.patientForm.controls.surname2.setValue(patient.surname2);
      this.patientForm.controls.sex.setValue(patient.sex);
      this.patientForm.controls.birthdate.setValue(this.appService.timestampWithMilis(patient.birthdate));
      this.patientForm.controls.email.setValue(patient.email);
      this.patientForm.controls.telephone.setValue(patient.telephone);
    }
  }

  updateData(): void {
    const patient = this.appService.getPatient();

    patient.privateId = this.patientForm.controls.privateId.value;
    patient.publicId = this.patientForm.controls.publicId.value;
    patient.nif = this.patientForm.controls.nif.value;
    patient.name = this.patientForm.controls.name.value;
    patient.surname1 = this.patientForm.controls.surname1.value;
    patient.surname2 = this.patientForm.controls.surname2.value;
    patient.sex = this.patientForm.controls.sex.value;
    patient.birthdate = this.appService.timestampWithoutMilis(this.patientForm.controls.birthdate.value);
    patient.email = this.patientForm.controls.email.value;
    patient.telephone = this.patientForm.controls.telephone.value;

    this.appService.setPatient(patient);
  }
}
