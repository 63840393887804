import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NZ_I18N, NzConfig } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { dateLocaleDE } from 'src/assets/i18n/date-locale/de';
import { dateLocaleEN } from 'src/assets/i18n/date-locale/en';
import { dateLocaleES } from 'src/assets/i18n/date-locale/es';
import { dateLocaleIT } from 'src/assets/i18n/date-locale/it';
import { dateLocaleCA } from 'src/assets/i18n/date-locale/ca';
import { dateLocaleEU } from 'src/assets/i18n/date-locale/eu';
import { ngZorroES } from 'src/assets/i18n/ng-zorro/es';
import { AppImports } from 'src/config/app.imports';
import { AppDeclarations } from 'src/config/app.declarations';
import { AppEntryComponents } from 'src/config/app.entry-components';

registerLocaleData(dateLocaleDE);
registerLocaleData(dateLocaleEN);
registerLocaleData(dateLocaleES);
registerLocaleData(dateLocaleIT);
registerLocaleData(dateLocaleCA);
registerLocaleData(dateLocaleEU);

const ngZorroConfig: NzConfig = {
  icon: { nzTwotoneColor: '#006766' }
};

@NgModule({
  declarations: AppDeclarations,
  imports: AppImports,
  entryComponents: AppEntryComponents,
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    {provide: NZ_I18N, useValue: ngZorroES}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
