export const ngZorroEN = {
    locale: 'en',
    Pagination: {
        items_per_page: '/ page',
        jump_to: 'Go to',
        jump_to_confirm: 'confirm',
        page: '',
        prev_page: 'Previous page',
        next_page: 'Next page',
        prev_5: '5 previous pages',
        next_5: '5 next pages',
        prev_3: '3 previous pages',
        next_3: '3 next pages'
    },
    DatePicker: {
        lang: {
            placeholder: 'Select date',
            rangePlaceholder: ['Start date', 'end date'],
            today: 'Today',
            now: 'Now',
            backToToday: 'Back to today',
            ok: 'Acept',
            clear: 'Clean',
            month: 'Month',
            year: 'Year',
            timeSelect: 'Select hour',
            dateSelect: 'Select date',
            monthSelect: 'Choose a month',
            yearSelect: 'Choose a year',
            decadeSelect: 'Choose a decade',
            yearFormat: 'yyyy',
            dateFormat: 'MM/dd/yyyy',
            dayFormat: 'dd',
            dateTimeFormat: 'MM/dd/yyyy HH:mm',
            monthBeforeYear: true,
            previousMonth: 'Previous month (PageUp)',
            nextMonth: 'Next month (PageUp)',
            previousYear: 'Previous year (Control + left)',
            nextYear: 'Next year (Control + left)',
            previousDecade: 'Previous decade',
            nextDecade: 'Next decade',
            previousCentury: 'Previous century',
            nextCentury: 'Next century'
        },
        timePickerLocale: {
            placeholder: 'Select'
        }
    },
    TimePicker: {
        placeholder: 'Select'
    },
    Calendar: {
        today: 'Today',
        now: 'Now',
        backToToday: 'Back to today',
        ok: 'Acept',
        clear: 'Clean',
        month: 'Month',
        year: 'Year',
        timeSelect: 'Choose a hour',
        dateSelect: 'Choose a date',
        monthSelect: 'Choose a month',
        yearSelect: 'Choose a year',
        decadeSelect: 'Choose a decade',
        yearFormat: 'yyyy',
        dateFormat: 'MM/dd/yyyy',
        dayFormat: 'dd',
        dateTimeFormat: 'MM/dd/yyyy HH:mm',
        monthBeforeYear: true,
        previousMonth: 'Previous month (PageUp)',
        nextMonth: 'Next month (PageUp)',
        previousYear: 'Previous year (Control + left)',
        nextYear: 'Next year (Control + left)',
        previousDecade: 'Previous decade',
        nextDecade: 'Next decade',
        previousCentury: 'Previous century',
        nextCentury: 'Next century'
    },
    global: {
        placeholder: 'Select'
    },
    Table: {
        filterTitle: 'Filter menu',
        filterConfirm: 'Accept',
        filterReset: 'Restart',
        selectAll: 'Select all',
        selectInvert: 'Invert selection',
        sortTitle: 'Order'
    },
    Modal: {
        okText: 'Accept',
        cancelText: 'Cancel',
        justOkText: 'Accept'
    },
    Popconfirm: {
        okText: 'Accept',
        cancelText: 'Cancel'
    },
    Transfer: {
        searchPlaceholder: 'Search here',
        itemUnit: 'lement',
        itemsUnit: 'elements'
    },
    Upload: {
        uploading: 'Going up…',
        removeFile: 'Delete file',
        uploadError: 'Error uploading file',
        previewFile: 'Preview'
    },
    Empty: {
        description: 'There is no data'
    },
    Icon: {
        icon: 'icon'
    },
    Text: {
        edit: 'Edit',
        copy: 'Copy',
        copied: 'Copied',
        expand: 'Expand'
    },
    PageHeader: {
        back: 'Return'
    }
};
