import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { formatDate } from '@angular/common';
import * as differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { ngZorroDE } from '../assets/i18n/ng-zorro/de';
import { ngZorroEN } from '../assets/i18n/ng-zorro/en';
import { ngZorroES } from '../assets/i18n/ng-zorro/es';
import { ngZorroIT } from '../assets/i18n/ng-zorro/it';
import { ngZorroCA } from '../assets/i18n/ng-zorro/ca';
import { ngZorroEU } from '../assets/i18n/ng-zorro/eu';
import { NzDrawerRef } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private patient: any;
  private drawerRef: NzDrawerRef<string>;
  private updateChartSubject = new BehaviorSubject({});

  public minWeight = 50;
  public maxWeight = 150;
  public minHeight = 120;
  public maxHeight = 220;
  public minDose = 100;
  public maxDose = 2000;
  public stepDose = 100;
  public fullPilMg = 400;
  public forgotEmailOK = false;

  constructor(
    private translateService: TranslateService,
    private nzI18nService: NzI18nService,
    private router: Router) { }

  getNgZorroLocale(lang: string): any {
    return (lang === 'en' ? ngZorroEN :
            lang === 'de' ? ngZorroDE :
            lang === 'it' ? ngZorroIT :
            lang === 'ca' ? ngZorroCA :
            lang === 'eu' ? ngZorroEU : ngZorroES);
  }

  getLang(): string {
    let lang = localStorage.getItem('lang');
    if (lang == null) {
      lang = this.translateService.getDefaultLang();
      this.setLang(lang);
    }
    return lang;
  }

  setLang(lang: string): void {
    localStorage.setItem('lang', lang);
  }

  changeLang(lang: string): void {
    this.setLang(lang);
    this.translateService.use(lang);
    this.nzI18nService.setLocale(this.getNgZorroLocale(lang));
    this.launchUpdateChart();
  }

  updateChartSubscription(): Observable<any> {
    return this.updateChartSubject.asObservable();
  }

  launchUpdateChart() {
    this.updateChartSubject.next({});
  }

  getUser(): any {
    return JSON.parse(sessionStorage.getItem('loggedUser'));
  }

  setUser(loggedUser: any): void {
    sessionStorage.setItem('loggedUser', JSON.stringify(loggedUser));
  }

  deleteUser(): void {
    sessionStorage.removeItem('loggedUser');
  }

  getFullUserName(): string {
    let fullName = this.getUser().name + ' ' + this.getUser().surname1;

    if (this.getUser().surname2) {
      fullName += ' ' + this.getUser().surname2;
    }
    return fullName;
  }

  getShortUserName(): string {
    return this.getUser().name.substr(0, 1).toUpperCase() + ' ' + this.getUser().surname1.substr(0, 1).toUpperCase();
  }

  getPatient(): any {
    return this.patient;
  }

  getPatientName(patient: any): string {
    let fullName = null;
    if (patient != null) {
      fullName = patient.name + ' ' + patient.surname1;
      if (patient.surname2) {
        fullName += ' ' + patient.surname2;
      }
    }
    return fullName;
  }

  setPatient(patient: any): void {
    this.patient = patient;
  }

  getDrawer(): NzDrawerRef {
    return this.drawerRef;
  }

  setDrawer(drawerRef: NzDrawerRef): void {
    this.drawerRef = drawerRef;
  }

  dateFormat(value: any) {
    return formatDate (this.timestampWithMilis(value), this.nzI18nService.getLocaleData('DatePicker.lang.dateFormat'), this.nzI18nService.getLocale().locale);
  }

  disabledFutureDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 0;
  }

  timestampWithoutMilis(time: number) {
    return Math.floor(new Date(time).getTime() / 1000);
  }

  timestampWithMilis(time: number) {
    return time * 1000;
  }

  startDateHour(startDate: Date): Date {
    startDate.setHours(0, 0, 0, 0);
    return startDate;
  }

  endDateHour(endDate: Date): Date {
    endDate.setHours(23, 59, 59, 999);
    return endDate;
  }

  changePrescription(prescription: any): any {
    const newPrescription = JSON.parse(JSON.stringify(prescription));
    newPrescription.sleepingHours = parseInt(prescription.sleepingHours, 10);
    newPrescription.intensityActivity = parseInt(prescription.intensityActivity, 10);
    newPrescription.adition1 = prescription.adition1 ? 1 : 0;
    newPrescription.adition2 = prescription.adition2 ? 1 : 0;
    return newPrescription;
  }

  getPillColor(value: number, color: string, part: number): string {
    return (value / this.fullPilMg) >= (this.stepDose / this.fullPilMg) * part ? color : '#FFFFFF';
  }

  getEmailPattern(): string {
    return '^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  }

  getCodePattern(): string {
    return '^[a-zA-Z0-9]{8}$';
  }

  getPasswordPattern(): string {
    return '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%&!?.,+*_-])([a-zA-Z0-9@#$%&!?.,+*_-].{7,})$';
  }

  getNIFPattern(): string {
    return '^(([X-Zx-z]{1})([0-9]{7})([A-Za-z]{1}))$|^(([0-9]{8})([A-Za-z]{1}))$';
  }

  getTelephonePattern(): string {
    return '^([0-9]{9})$';
  }

  getNamePattern(): string {
    return '^[a-zA-ZñÑáéíóúÁÉÍÓÚ\\s]*$';
  }
}
