import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/services/app.service';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.css']
})
export class ResetPassComponent implements OnInit {

  resetPassForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService) {}

  ngOnInit() {
    this.resetPassForm = this.formBuilder.group({
      oldPass: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getPasswordPattern())]),
      newPass: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getPasswordPattern())]),
      newPass2: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getPasswordPattern())])
    });
  }
}
