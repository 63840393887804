import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppService } from 'src/services/app.service';

@Component({
  selector: 'app-update-pass',
  templateUrl: './update-pass.component.html',
  styleUrls: ['./update-pass.component.css']
})
export class UpdatePassComponent implements OnInit {

  updatePassForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService) {}

  ngOnInit() {
    this.updatePassForm = this.formBuilder.group({
      email: new FormControl({value: this.appService.getUser().email, disabled: false}),
      oldPass: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getPasswordPattern())]),
      newPass: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getPasswordPattern())]),
      newPass2: new FormControl(null, [Validators.required, Validators.pattern(this.appService.getPasswordPattern())])
    });
  }
}
