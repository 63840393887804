export const ngZorroEU = {
    locale: 'eu',
    Pagination: {
        items_per_page: 'Orrialde/Itemak',
        jump_to: 'Joan',
        jump_to_confirm: 'Baieztatu',
        page: '',
        prev_page: 'Aurreko orria',
        next_page: 'Hurrengo orria',
        prev_5: 'Aurreko 5 orriak',
        next_5: 'Hurrengo 5 orriak',
        prev_3: 'Aurreko 3 orriak',
        next_3: 'Hurrengo 3 orriak'
    },
    DatePicker: {
        lang: {
            placeholder: 'Data bilatu',
            rangePlaceholder: ['Hasiera data', 'Amaiera data'],
            today: 'Gaur',
            now: 'Orain',
            backToToday: 'Gaurko egunera itzuli',
            ok: 'Onartu',
            clear: 'Garbitu',
            month: 'Hilabetea',
            year: 'Urtea',
            timeSelect: 'Ordua aukeratu',
            dateSelect: 'Data aukeratu',
            monthSelect: 'Hilabetea aukeratu',
            yearSelect: 'Urtea aukeratu',
            decadeSelect: 'Hamarkada aukeratu',
            yearFormat: 'yyyy',
            dateFormat: 'yyyy/MM/dd',
            dayFormat: 'dd',
            dateTimeFormat: 'yyyy/MM/dd HH:mm',
            monthBeforeYear: true,
            previousMonth: 'Aurreko hilabetea (PageUp)',
            nextMonth: 'Hurrengo hilabetea (PageDown)',
            previousYear: 'Aurreko urtea (Control + left)',
            nextYear: 'Hurrengo urtea (Control + right)',
            previousDecade: 'Aurreko hamarkada',
            nextDecade: 'Hurrengo hamarkada',
            previousCentury: 'Aurreko mendea',
            nextCentury: 'Hurrengo mendea'
        },
        timePickerLocale: {
            placeholder: 'Ordua aukeratu'
        }
    },
    TimePicker: {
        placeholder: 'Ordua aukeratu'
    },
    Calendar: {
        today: 'Gaur',
        now: 'Orain',
        backToToday: 'Gaurko egunera itzuli',
        ok: 'Onartu',
        clear: 'Garbitu',
        month: 'Hilabetea',
        year: 'Urtea',
        timeSelect: 'Ordua aukeratu',
        dateSelect: 'Data aukeratu',
        monthSelect: 'Hilabetea aukeratu',
        yearSelect: 'Urtea aukeratu',
        decadeSelect: 'Hamarkada aukeratu',
        yearFormat: 'yyyy',
        dateFormat: 'yyyy/MM/dd',
        dayFormat: 'dd',
        dateTimeFormat: 'yyyy/MM/dd HH:mm',
        monthBeforeYear: true,
        previousMonth: 'Aurreko hilabetea (PageUp)',
        nextMonth: 'Hurrengo hilabetea (PageDown)',
        previousYear: 'Aurreko urtea (Control + left)',
        nextYear: 'Hurrengo urtea (Control + right)',
        previousDecade: 'Aurreko hamarkada',
        nextDecade: 'Hurrengo hamarkada',
        previousCentury: 'Aurreko mendea',
        nextCentury: 'Hurrengo mendea'
    },
    global: {
        placeholder: 'Aukeratu'
    },
    Table: {
        filterTitle: 'Filtratu',
        filterConfirm: 'Onartu',
        filterReset: 'Berrabiarazi',
        selectAll: 'Guztia hautatu',
        selectInvert: 'Haukeraketa inbertitu',
        sortTitle: 'Ordenatu'
    },
    Modal: {
        okText: 'Onartu',
        cancelText: 'Deuseztatu',
        justOkText: 'Onartu'
    },
    Popconfirm: {
        okText: 'Onartu',
        cancelText: 'Deuseztatu'
    },
    Transfer: {
        searchPlaceholder: 'Bilatu',
        itemUnit: 'Elementua',
        itemsUnit: 'Elementuak'
    },
    Upload: {
        uploading: 'Igotzen...',
        removeFile: 'Fitxategia ezabatu',
        uploadError: 'Errorea fitxategia igotzean',
        previewFile: 'Aurrebista'
    },
    Empty: {
        description: 'Ez dago daturik'
    },
    Icon: {
        icon: 'Ikonoa'
    },
    Text: {
        edit: 'Editatu',
        copy: 'Kopiatu',
        copied: 'Kopiatua',
        expand: 'Hedatu'
    },
    PageHeader: {
        back: 'Itzuli'
    }
};
