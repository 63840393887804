import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/services/api.service';
import { AppService } from 'src/services/app.service';

@Component({
  selector: 'app-prescription-page',
  templateUrl: './prescription-page.component.html',
  styleUrls: ['./prescription-page.component.css']
})
export class PrescriptionPageComponent implements OnInit {

  @Input() prescription: any;

  isSaving = false;
  loading = false;

  constructor(private translateService: TranslateService,
              private notificationService: NzNotificationService,
              private appService: AppService,
              private apiService: ApiService,
              private router: Router) { }

  ngOnInit() {
    this.onChangeLitium();
  }

  savePrescription(): void {
    if (this.prescription.sleepingHours == null ) {
      this.notificationService.create('error', this.translateService.instant('error.validateError'), this.translateService.instant('error.incorrectSleepingHours'));
    } else if (this.prescription.intensityActivity == null) {
      this.notificationService.create('error', this.translateService.instant('error.validateError'), this.translateService.instant('error.incorrectIntensityActivity'));
    } else {
      this.isSaving = true;
      const newPrescription = this.appService.changePrescription(this.prescription);
      this.apiService.savePrescription(newPrescription)
        .then(data => {
          if (data.code === 0) { // OK
            this.notificationService.create('success', this.translateService.instant('prescriptionPage.prescTreatment'), this.translateService.instant('success.successSaveTreatment'));
            this.apiService.getPatient(this.prescription.privateId).then(patientData => {
              this.appService.setPatient(patientData);
              this.router.navigate(['/patientPage/treatmentChart']);
            });
          } else if (data.code === 1) { // ERROR
            this.notificationService.create('error', this.translateService.instant('error.saveError'), this.translateService.instant('error.errorDuplicateTreatment'));
          } else { // ERROR
            this.notificationService.create('error', this.translateService.instant('error.saveError'), this.translateService.instant('error.errorSaveTreatment'));
          }
        }).finally(() => {
          this.isSaving = false;
        });
    }
  }

  onChangeLitium() {
    this.loading = true;
    const newPrescription = this.appService.changePrescription(this.prescription);

    this.apiService.getLithemiaPredict(newPrescription)
      .then(lithemiaValue => {
        this.prescription.realLithemia = lithemiaValue.lithemia;
      }).finally(() => {
        this.loading = false;
      });
  }

  getRealPillColor(part: number): string {
    return this.appService.getPillColor(this.prescription.realDose, '#006766', part);
  }
}
