export const environment = {
  api_endpoint: 'https://a76gqsdz6j.execute-api.eu-west-1.amazonaws.com/v1',
  session_expired_code: 401,
  session_timer_milis: 600000,
  production: false
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
