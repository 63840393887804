import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/services/app.service';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  selectedPatientName: string;
  patients: any;
  filteredPatients: any;
  searching = false;

  constructor(private router: Router,
              private appService: AppService,
              private apiService: ApiService,
              private translateService: TranslateService,
              private notificationService: NzNotificationService) {
  }

  ngOnInit(): void {
    this.apiService.getPatients().then(patiens => {
      this.patients = patiens;
      this.filteredPatients = patiens;
    });
  }

  onChange(value: string): void {
    this.filteredPatients = this.patients.filter(patient => {
      return patient.publicId.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
              patient.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
              patient.surname1.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
              (patient.surname2 != null && patient.surname2 !== '' && patient.surname2.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    });
  }

  goPatientPage(privateId: string): void {
    this.searching = true;
    this.apiService.getPatient(privateId).then(patientData => {
      this.appService.setPatient(patientData);
      this.router.navigate(['/patientPage']);
    }).finally( () => this.searching = false);
  }
}
